// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 分页获取数据
export const getWxUserList = (params) => {
    return postRequest('/busi/wxUser/getByCondition', params)
}
// 添加
export const addWxUser = (params) => {
    return postRequest('/busi/wxUser/insert', params)
}
// 编辑
export const editWxUser = (params) => {
    return postRequest('/busi/wxUser/update', params)
}
// 删除
export const deleteWxUser = (params) => {
    return postRequest('/busi/wxUser/delByIds', params)
}