<template>
    <div class="wxUserAddEdit">
        <!-- Drawer抽屉 -->
        <Drawer :title="title" v-model="visible" width="720" on-close="handleClose()" :mask-closable="false">
            <Form ref="form" :model="form" :label-width="120" :rules="formValidate" label-position="left">
                <FormItem label="微信id" prop="openid">
                    <Input v-model="form.openid" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="手机" prop="telno">
                    <Input v-model="form.telno" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="微信昵称" prop="nickname">
                    <Input v-model="form.nickname" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="性别" prop="sex">
                    <Input v-model="form.sex" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="国家" prop="country">
                    <Input v-model="form.country" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="省份" prop="province">
                    <Input v-model="form.province" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="城市" prop="city">
                    <Input v-model="form.city" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="头像地址" prop="headimgurl">
                    <Input v-model="form.headimgurl" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="unionid" prop="unionid">
                    <Input v-model="form.unionid" clearable style="width:100%"/>
                </FormItem>
                <Form-item class="br">
                    <Button @click="handleSubmit" :loading="submitLoading">保存</Button>
                    <Button @click="handleClose">关闭</Button>
                </Form-item>
            </Form>
        </Drawer>
    </div>
</template>
<script>
	// 根据你的实际请求api.js位置路径修改
	import {
		addWxUser,
		editWxUser
	} from "@/api/busi/data-manage/wxUser";
	export default {
		name: "wxUserAddEdit",
		components: {},
		props: {
			data: Object,
			addEditFlag: String,
			value: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				submitLoading: false, // 表单提交状态
				// TODO
				title: "本文标题",
				visible: this.value,
                form: { // 添加或编辑表单对象初始化数据
                    openid: "",
                    telno: "",
                    nickname: "",
                    sex: "",
                    country: "",
                    province: "",
                    city: "",
                    headimgurl: "",
                    unionid: "",
                },
				formExt: {
				},
                // 表单验证规则
                formValidate: {
                }
            };
        },
		methods: {
			init() {
				// 编辑情况下
				if (this.addEditFlag == "2") {
					this.form = this.data;
				}else{
					delete this.form.id;
				}
			},
			setCurrentValue(value) {
				if (value === this.visible) {
					this.$refs.form.resetFields();
				 return;
				}
				this.init();
				this.visible = value;
			},
			handleReset() {
				this.$refs.form.resetFields();
			},
			handleSubmit() {
				this.$refs.form.validate(valid => {
					if (valid) {
						if (this.addEditFlag == "1") {
							addWxUser(this.form).then(res => {
								this.submitLoading = false;
								if (res.success) {
									this.$Message.success("操作成功");
									this.$emit("on-submit", true);
									this.visible = false;
								}
							});
						}else{
							editWxUser(this.form).then(res => {
								this.submitLoading = false;
								if (res.success) {
									this.$Message.success("操作成功");
									this.$emit("on-submit", true);
									this.visible = false;
								}
							});
						}
					}
				});
			},
			handleClose(){
				this.visible = false;
			}

		},
		watch: {
			value(val) {
				this.setCurrentValue(val);
			},
			visible(value) {
				this.$emit("input", value);
			},
		},
		mounted() {
			this.init();
		}
	};
</script>

<style lang="less">
// 建议引入通用样式 具体路径自行修改
//@import "../../../styles/table-common.less";
</style>